import React, { FC, useEffect, useState } from "react";
import { Circle, Tooltip } from "react-leaflet";
import { trimText, RED, GREEN, OPACITY } from "../RNMapView";

interface Props {
  isBooked: boolean;
  name: string;
  circle: {
    id: string;
    latitude: number;
    longitude: number;
    radius: number;
  };
  showTooltips?: boolean;
  showRoomMarkers?: boolean;
}

const RNCircle: FC<Props> = ({
  isBooked,
  name,
  circle,
  showRoomMarkers,
  showTooltips,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showRoomMarker, setShowRoomMarker] = useState(false);

  useEffect(() => {
    if (showTooltips === undefined || showRoomMarkers === undefined) {
      return;
    }

    setShowTooltip(showTooltips);
    setShowRoomMarker(showRoomMarkers);
  }, [showTooltips, showRoomMarkers]);

  return (
    <div>
      <Circle
        center={[circle.latitude, circle.longitude]}
        radius={circle.radius}
        pathOptions={{
          color: isBooked ? RED : GREEN,
          fillColor: isBooked ? RED : GREEN,
        }}
        fillOpacity={OPACITY}
        weight={2}
      >
        {showTooltip && !showRoomMarker && (
          <Tooltip
            className={`room-permanent-tooltip`}
            direction="center"
            offset={[0, -10]}
            opacity={1}
            permanent
          >
            <div>
              <span className={isBooked ? "booked" : "free"}>
                {trimText(name || "", 18)}
              </span>
            </div>
          </Tooltip>
        )}
      </Circle>
    </div>
  );
};

export { RNCircle };
