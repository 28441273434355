import React, { FC, useEffect, useState, useRef } from "react";
import { Polygon, Tooltip } from "react-leaflet";
import { trimText, RED, GREEN, OPACITY } from "../RNMapView";

interface Props {
  id: string;
  isBooked: boolean;
  name: string;
  polygonal: {
    latitude: number;
    longitude: number;
  }[];
  eventHandlers?: L.LeafletEventHandlerFnMap;
  showTooltips?: boolean;
  showRoomMarkers?: boolean;
}

const RNPolygonal: FC<Props> = ({
  id,
  isBooked,
  name,
  eventHandlers,
  polygonal,
  showRoomMarkers,
  showTooltips,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showRoomMarker, setShowRoomMarker] = useState(false);

  useEffect(() => {
    if (showTooltips === undefined || showRoomMarkers === undefined) {
      return;
    }

    setShowTooltip(showTooltips);
    setShowRoomMarker(showRoomMarkers);
  }, [showTooltips, showRoomMarkers]);

  return (
    <div>
      <Polygon
        positions={polygonal.map((p) => [p.latitude, p.longitude])}
        fillOpacity={OPACITY}
        weight={2}
        pathOptions={{
          color: isBooked ? RED : GREEN,
          fillColor: isBooked ? RED : GREEN,
        }}
        eventHandlers={eventHandlers}
      >
        {showTooltip && !showRoomMarker && (
          <Tooltip
            className={`room-permanent-tooltip`}
            direction="center"
            offset={[0, -10]}
            opacity={1}
            permanent
          >
            <div>
              <span className={isBooked ? "booked" : "free"}>
                {trimText(name || "", 18)}
              </span>
            </div>
          </Tooltip>
        )}
      </Polygon>
    </div>
  );
};

export { RNPolygonal };
