import React, { useEffect, useMemo, useState } from "react";
import L from "leaflet";
import { MapContainer, ImageOverlay, MapConsumer, Marker } from "react-leaflet";
import useLocalStorageState from "use-local-storage-state";
import { FloorChildrenType, useFloorData } from "../../../Floors/lib/floorData";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { useDefineBounds } from "../../../Floors/lib/useDefineBounds";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useBookingsMarkers } from "../helpers/useBookingsMarkers";
import { useBookingsMapContext } from "../Context/BookingsMapContext";

import { getImageSize } from "../../../../api/external-api/react-native-map-view/utils/overlay.utils";
import { floorPlanHelpers } from "../../../Floors/lib/floorPlanHelpers";

import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { FloorFields } from "../../../../api/graphql/floors/floors";
import { FloorMapResponse } from "../../../../api/grpc/workplaces/workplaces";
import { BookingsMapMarkerList } from "../BookingsMapMarkers/BookingsMapMarkerList";
import { EmptyEntity } from "../../../shared/EmptyEntity/EmptyEntity";
import { BookingsButtonsModal } from "../BookingsMapModal/BookingsButtonsModal";
import { BusyTimeSlots } from "../../../../api/grpc/workplaces/ggevent/ggevent";
import { BookingsMapPopUp } from "../BookingsMapMarkers/BookingsMapPopUp";
import { BookingsFloorMapMarker } from "./BookingsFloorMapMarker";
import { BookingsUserPopup } from "../BookingsMapMarkers/BookingsUserPopup";
import { BookingTypes } from "../../BookingsSearch/BookingsSearch";
import { FloorMarkersSettings } from "../../../Floors/FloorMarkersSettings/FloorMarkersSettings";
import { FloorMapActionsGroup } from "../../../Floors/FloorMapActionsGroup/FloorMapActionsGroup";
import { Size } from "../../../Floors/FloorPlan/FloorsPlan";
import { MainResources } from "../../../../lib/types/main.types";
import { BookingsMapShapes } from "../BookingsMapShapes/BookingsMapShapes";

import "../../../Floors/FloorPlan/FloorContent.scss";

export interface ActiveWorkplace {
  id: string;
  type: string;
  typeOfWorkplace: MainResources;
  busyTimeSlots: BusyTimeSlots[];
}

interface Props {
  data: FloorFields;
  floorDataMarker?: FloorMapResponse;
  refetch: () => void;
}

export interface MapEntityModal {
  id: string;
  type: BookingTypes;
  modalType: "delete" | "add";
  busySlots: BusyTimeSlots[];
}

export const BookingsFloorMap = ({ data, floorDataMarker, refetch }: Props) => {
  const { pathname } = useRouter();
  const hasMarkerOpened = pathname.toLowerCase().includes("markeropen");
  const [mapImageSize, setMapImageSize] = useState<Size | null>(null);
  const [openMarkerId, setOpenMarkerId] = useState<undefined | string>(() => {
    if (hasMarkerOpened) {
      return pathname.split("=")[1];
    }

    return undefined;
  });
  const [openRoom, setOpenRoom] = useState<FloorChildrenType | undefined>(
    undefined
  );

  const [workplaceToReserve, setWorkplaceToReserve] =
    useState<null | ActiveWorkplace>(null);

  const { user } = useAuthContext();
  const [markersScaleFactor, setMarkersScaleFactor] = useLocalStorageState(
    `markers-scale-factor-${data?.id}`,
    {
      defaultValue: 1,
    }
  );

  const { desksOfFloor, roomsOfFloor } = useFloorData(floorDataMarker);
  const { getScaledIcon } = floorPlanHelpers();
  const {
    markers,
    shapes: { _circles: circles, _polygonal: polygonal },
  } = useBookingsMarkers(roomsOfFloor, desksOfFloor, {
    showRoomMarkers: floorDataMarker?.showRoomMarkers || false,
  });
  const { setReserveLaterTime } = useBookingsMapContext();

  const { validateInputNumber } = floorPlanHelpers();

  const { overlayBounds } = useDefineBounds(mapImageSize);

  const handleMapEntityReservePress = ({
    id,
    type,
    modalType,
    busySlots,
  }: MapEntityModal) => {
    setWorkplaceToReserve({
      id: id,
      type: modalType,
      typeOfWorkplace: type,
      busyTimeSlots: busySlots,
    });
  };

  const handleShapeClick = (id: string) => {
    const room = roomsOfFloor.find((room) => room.id === id);

    if (room) {
      setOpenRoom(room);
    }
  };

  const roomOfShape = useMemo(() => {
    // to open the shape popup we have two sources, one openRoom which comes from clicking the shape(handleShapeClick)
    // and second is openMarkerId which comes from other pages where Open on map button is clicked
    if (openRoom) {
      return openRoom;
    }

    if (hasMarkerOpened && openMarkerId) {
      const room = roomsOfFloor.find((room) => room.id === openMarkerId);
      const roomIsShape =
        !!room?.polygonal?.length || room?.circleShape?.latitude !== 0;

      return roomIsShape ? room : undefined;
    } else {
      return undefined;
    }
  }, [openRoom, hasMarkerOpened, openMarkerId, roomsOfFloor]);

  useEffect(() => {
    // isMounted below is to fix memory leak warning:
    let isMounted = true;

    setMapImageSize(null);

    if (isMounted) {
      getImageSize(data?.map?.url || "").then(setMapImageSize);
    }

    return () => {
      isMounted = false;
    };
  }, [data]);

  return (
    <div className="FloorContent">
      <div className="FloorContent__plan">
        {data?.map === null ? (
          <EmptyEntity type="FloorMap" />
        ) : (
          <>
            <MapContainer
              crs={L.CRS.Simple}
              center={[0, 0]}
              zoom={0}
              maxZoom={3}
              minZoom={-3}
              // maxBoundsViscosity={1.0}
              // maxBounds={bounds}
              zoomSnap={0.15}
              zoomDelta={0.5}
              id="map-container"
              doubleClickZoom={false}
              scrollWheelZoom={true}
              touchZoom={true}
            >
              <FloorMapActionsGroup>
                <FloorMarkersSettings
                  scaleFactor={markersScaleFactor}
                  onScaleFactorChange={setMarkersScaleFactor}
                />
              </FloorMapActionsGroup>
              <MapConsumer>
                {(map) => {
                  map.addEventListener("click", () => {
                    if (openMarkerId) {
                      return setOpenMarkerId(undefined);
                    }
                  });

                  return null;
                }}
              </MapConsumer>
              {data?.map?.url !== undefined ? (
                <>
                  {markers.map(
                    (
                      {
                        id,
                        marker,
                        isBooked,
                        isAssigned,
                        isBlocked,
                        location,
                        type,
                        name,
                        tags,
                        username,
                        avatar,
                        busySlot,
                        attachedUserPopup,
                        attendeeImage,
                        amenities,
                        numberOfSeats,
                        assigneAllowToFind,
                        assigneEmail,
                      },
                      index
                    ) => {
                      return (
                        <BookingsFloorMapMarker
                          key={`${id}-${index}`}
                          position={marker.getLatLng()}
                          name={name}
                          type={type}
                          isBooked={isBooked}
                          icon={
                            marker.options.alt === "User marker"
                              ? (marker.options.icon as L.DivIcon)
                              : getScaledIcon(
                                  marker.getIcon() as L.Icon,
                                  markersScaleFactor
                                )
                          }
                          defaultOpen={
                            openMarkerId === undefined
                              ? undefined
                              : openMarkerId === id
                          }
                        >
                          <BookingsMapPopUp
                            id={id}
                            isBooked={isBooked}
                            isAssigned={isAssigned}
                            isBlocked={isBlocked}
                            location={location}
                            type={type}
                            name={name}
                            tags={tags}
                            username={username}
                            avatar={avatar}
                            busySlots={busySlot}
                            email={user?.email}
                            amenities={amenities}
                            numberOfSeats={numberOfSeats}
                            assigneAllowToFind={assigneAllowToFind}
                            assigneEmail={assigneEmail}
                            handleOpenModal={() => {
                              handleMapEntityReservePress({
                                id,
                                type: type.toLowerCase() as BookingTypes,
                                modalType: isBooked ? "delete" : "add",
                                busySlots: busySlot,
                              });
                            }}
                            handleReserveLaterModal={() => {
                              if (isBooked && busySlot.length > 0) {
                                setReserveLaterTime(
                                  new Date(busySlot[0].endTime)
                                );
                              }

                              handleMapEntityReservePress({
                                id,
                                type: type.toLowerCase() as BookingTypes,
                                modalType: "add",
                                busySlots: busySlot,
                              });
                            }}
                          />
                        </BookingsFloorMapMarker>
                      );
                    }
                  )}

                  <BookingsMapShapes
                    circles={circles}
                    polygonal={polygonal}
                    showRoomMarkers={floorDataMarker?.showRoomMarkers || false}
                    showTooltips={floorDataMarker?.showTooltips || false}
                    handleShapeClick={handleShapeClick}
                    handleMapEntityReservePress={handleMapEntityReservePress}
                    room={roomOfShape}
                  />

                  {!!overlayBounds && (
                    <ImageOverlay
                      url={data.map.url}
                      opacity={validateInputNumber(data.map.opacity)}
                      // bounds={bounds}
                      bounds={overlayBounds}
                    />
                  )}
                </>
              ) : (
                <LoadingBox />
              )}
            </MapContainer>
          </>
        )}
      </div>
      <div className="FloorContent__children FloorContent__children--bookings">
        <BookingsMapMarkerList
          desks={desksOfFloor}
          rooms={roomsOfFloor}
          refetch={refetch}
          handleOpenMarker={(e: string) => {
            if (!!openMarkerId && e === openMarkerId) {
              setOpenRoom(undefined);

              return setOpenMarkerId(undefined);
            }

            handleShapeClick(e);

            return setOpenMarkerId(e);
          }}
          email={user?.email}
          hasUrl={!!floorDataMarker?.url.length}
        />
      </div>
      {!!workplaceToReserve && (
        <BookingsButtonsModal
          isOpen
          toggleModal={() => setWorkplaceToReserve(null)}
          id={workplaceToReserve.id}
          type={workplaceToReserve.type}
          typeOfWorkplace={workplaceToReserve.typeOfWorkplace}
          busySlots={workplaceToReserve.busyTimeSlots}
          refetch={refetch}
        />
      )}
    </div>
  );
};
