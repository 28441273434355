import React, { useMemo } from "react";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { useManageShapes } from "./lib/useManageShapes";
import { useEmptyRoomsAndDesks } from "../../Bookings/lib/useEmptyRoomsAndDesks";
import { useMarker } from "../lib/useMarkerData";
import { useTypesHelper } from "../lib/useTypesHelper";
import { trimText } from "../../../lib/helpers/trimText";

import { Icon } from "../../shared/Icon/Icon";
import { AddMarkerButton } from "../MarkerButtons/AddMarkerButton";
import { FloorFields } from "../../../api/graphql/floors/floors";
import { DeleteMarkerButton } from "../MarkerButtons/DeleteMarkerButton";
import { EntityData, FloorChildrenList } from "./FloorChildrenList";

export type FloorChildrenType = "room" | "desk" | "wayfinder";
export type TypeOfFloorChildrenMethod = "add" | "delete";

interface Props {
  data: FloorFields;
  onClick: (id: string) => void;
  onClickShape: (id: string, type: "circle" | "polygonal") => void;
  handleAddRoomButtonClick: (id?: string) => void;
}

export const FloorChildrenDefaultList = ({
  data,
  onClick,
  onClickShape,
  handleAddRoomButtonClick,
}: Props) => {
  const { user } = useAuthContext();

  const { methodTypes, markerDataTypes } = useTypesHelper();
  const { roomsOfFloor, desksOfFloor, wayfindersfFloor } = useMarker(data);
  const { handleRoomCircleDelete, handleRoomPolygonalDelete } = useManageShapes(
    user?.customerid || ""
  );

  const emptyData = useEmptyRoomsAndDesks(
    roomsOfFloor,
    desksOfFloor,
    wayfindersfFloor
  );

  // we check if map exists and store it in the variable below
  // if it doesnt exists we dont need to show Add/Delete buttons in rooms/desks list
  const mapDoesntExists = data?.map === null;

  const handleOpenMarkerPopup = (item: any) => async () => {
    const hasPolygonal = item?.polygonal?.length > 0;
    const hasCircle = item?.circleShape?.latitude !== 0;

    const markerIsOnMap =
      item?.marker?.latitude !== 0 || hasPolygonal || hasCircle;

    if (!markerIsOnMap) {
      return;
    }

    if (item.__typename === "Room") {
      if (hasPolygonal || hasCircle) {
        return onClickShape(item.id, hasPolygonal ? "polygonal" : "circle");
      }
    }

    return onClick(item.id);
  };

  const handleFloorChildrenUpdate =
    (
      id: string,
      type: FloorChildrenType,
      typeOfMethod: TypeOfFloorChildrenMethod
    ) =>
    async () => {
      let methodName = methodTypes[type];
      let methodType = markerDataTypes[typeOfMethod];

      return await methodName({
        id: id,
        marker: methodType,
        tenantId: user?.customerid || "",
      });
    };

  const rowsData: EntityData[] = useMemo(() => {
    const sortedRooms = roomsOfFloor
      ? [...roomsOfFloor].sort((a, b) => a.name.localeCompare(b.name))
      : [];
    const sortedDesks = desksOfFloor
      ? [...desksOfFloor].sort((a, b) => a.name.localeCompare(b.name))
      : [];
    const sortedWayfinders = wayfindersfFloor
      ? [...wayfindersfFloor].sort((a, b) => a.name.localeCompare(b.name))
      : [];

    return [
      {
        data:
          data?.rooms &&
          sortedRooms.map((item: any) => {
            return {
              name: (
                <span
                  onClick={handleOpenMarkerPopup(item)}
                  className={
                    (!!item?.marker?.latitude ||
                      !!item?.circleShape?.latitude) &&
                    !mapDoesntExists
                      ? "primary--text"
                      : ""
                  }
                >
                  {trimText(item.name, 20)}
                </span>
              ),
              location: item.zoneName ? trimText(item.zoneName, 20) : "",
              add:
                // item.marker.latitude is a number value that is bigger or lower than 0 if the marker is in map
                // we can use this in multiple cases to check if marker is on map as seen here, if its = 0 it means is not on the map
                mapDoesntExists ? (
                  <></>
                ) : item?.marker?.latitude === 0 &&
                  (item?.circleShape === null ||
                    item?.circleShape?.latitude === 0) &&
                  !item?.polygonal?.length ? (
                  <AddMarkerButton
                    parentId={item.id}
                    className="AddMarkerButton"
                    onClick={handleFloorChildrenUpdate(item.id, "room", "add")}
                    allowShapeDraw
                    handleAddRoomButtonClick={handleAddRoomButtonClick}
                  />
                ) : (
                  <DeleteMarkerButton
                    className="DeleteMarkerButton"
                    color="third"
                    onClick={() => {
                      if (item?.circleShape?.latitude !== 0) {
                        handleRoomCircleDelete(item.id)();
                      }
                      if (item?.polygonal?.length) {
                        handleRoomPolygonalDelete(item.id, item.polygonal)();
                      }
                      if (item?.marker?.latitude !== 0) {
                        handleFloorChildrenUpdate(item.id, "room", "delete")();
                      }
                    }}
                  />
                ),
            };
          }),
        type: "Room",
        icon: "rooms-icon",
      },
      {
        data:
          data?.desks &&
          sortedDesks.map((item: any) => {
            return {
              name: (
                <span
                  onClick={handleOpenMarkerPopup(item)}
                  className={!!item?.marker?.latitude ? "primary--text" : ""}
                >
                  {trimText(item.name, 20)}
                  {!!item.account ? <Icon icon="single-user-icon" /> : ""}
                </span>
              ),
              location: item.zoneName ? trimText(item.zoneName, 25) : "",
              add: mapDoesntExists ? (
                <></>
              ) : item?.marker?.latitude === 0 ? (
                <AddMarkerButton
                  className="AddMarkerButton"
                  onClick={handleFloorChildrenUpdate(item.id, "desk", "add")}
                />
              ) : (
                <DeleteMarkerButton
                  className="DeleteMarkerButton"
                  color="third"
                  onClick={handleFloorChildrenUpdate(item.id, "desk", "delete")}
                />
              ),
            };
          }),
        type: "Desk",
        icon: "desk_icon_48x48",
      },
      {
        data:
          data?.wayfinders &&
          sortedWayfinders.map((item: any) => {
            return {
              name: (
                <span
                  onClick={handleOpenMarkerPopup(item)}
                  className={!!item?.marker?.latitude ? "primary--text" : ""}
                >
                  {trimText(item.name, 20)}
                  {!!item.account ? <Icon icon="single-user-icon" /> : ""}
                </span>
              ),
              location: item.zoneName ? trimText(item.zoneName, 25) : "",
              add: mapDoesntExists ? (
                <></>
              ) : item.marker === null || item?.marker?.latitude === 0 ? (
                <AddMarkerButton
                  className="AddMarkerButton"
                  onClick={handleFloorChildrenUpdate(
                    item.id,
                    "wayfinder",
                    "add"
                  )}
                />
              ) : (
                <DeleteMarkerButton
                  className="DeleteMarkerButton"
                  color="third"
                  onClick={handleFloorChildrenUpdate(
                    item.id,
                    "wayfinder",
                    "delete"
                  )}
                />
              ),
            };
          }),
        type: "Wayfinder",
        icon: "wayFinder_icon_48x48",
      },
    ];
  }, [data]);

  return emptyData ? (
    <h4 className="FloorChildrenDefaultList__empty">
      No rooms, desks or wayfinders
    </h4>
  ) : (
    <>
      <div className="FloorContent__children--header flex-a-center mb-2">
        <Icon icon="add-workplace-icon" />

        <h5>Add workspaces to your map</h5>
      </div>
      <span className="label__main">
        Press the "+" button to draw shapes over your rooms, or place a marker
        in the correct spot. The workspace items will be automatically assigned.
      </span>

      <div className="mt-4">
        <FloorChildrenList rows={rowsData} />
      </div>
    </>
  );
};
