import React from "react";
import { toast } from "react-toastify";

import { Popup } from "../../../shared/Popup/Popup";
import { Icon } from "../../../shared/Icon/Icon";
import { INFO_STRINGS } from "../../../../lib/utils/constants";

import "./TimeSelector.scss";

interface Props {
  selectedHour: number | null;
  selectedMinute: number | null;
  onDurationChange: (hour: number, minute: number) => void;
  is24Hour?: boolean;
}

const snapToNearestFive = (value: number) => {
  return Math.round(value / 5) * 5;
};

const formatTwoDigits = (value: number) => {
  return value.toString().padStart(2, "0");
};

export const TimeSelector = ({
  selectedHour,
  selectedMinute,
  onDurationChange,
  is24Hour = false,
}: Props) => {
  const displayTime = () => {
    if (is24Hour) {
      return "24h 00m";
    }

    const hourDisplay =
      selectedHour !== null ? `${formatTwoDigits(selectedHour)}h` : "00h";
    const minuteDisplay =
      selectedMinute !== null ? `${formatTwoDigits(selectedMinute)}m` : "00m";
    return `${hourDisplay} ${minuteDisplay}`;
  };

  const handleHourChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hour = parseInt(event.target.value, 10);
    onDurationChange(hour, selectedMinute || 0);
  };

  const handleMinuteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const minute = parseInt(event.target.value, 10);
    onDurationChange(selectedHour || 0, minute);
  };

  return (
    <div className="flex-1">
      <label>For</label>
      <Popup
        trigger={() => (
          <div className="TimeSelector flex-a-center cursor-pointer">
            <span className="pl-1 text-14">{displayTime()}</span>
          </div>
        )}
        position="bottom left"
        hideCloseButton
        className="TimeSelector__popup"
        title="Duration"
      >
        <div className="TimeSelector__content">
          <div className="TimeSelector__content--head">
            <span className="text-secondary">Hours</span>
            <span className="text-secondary">Minutes</span>
          </div>
          <div className="TimeSelector__content--body">
            <div className="TimeSelector__content--body--group">
              <Icon
                className="rotate-top"
                icon="chevron-down"
                onClick={() => {
                  if (is24Hour) {
                    return toast.warn(INFO_STRINGS.disableAllDay);
                  }

                  if (selectedHour === 23) {
                    return onDurationChange(0, selectedMinute || 0);
                  }

                  onDurationChange(
                    (selectedHour || 0) + 1,
                    selectedMinute || 0
                  );
                }}
              />

              <input
                type="number"
                min="0"
                max="23"
                step="1"
                value={is24Hour ? "24" : formatTwoDigits(selectedHour || 0)}
                onChange={handleHourChange}
                tabIndex={-1}
                readOnly
              />

              <Icon
                icon="chevron-down"
                onClick={() => {
                  if (is24Hour) {
                    return toast.warn(INFO_STRINGS.disableAllDay);
                  }

                  if (selectedHour === 0) {
                    return onDurationChange(23, selectedMinute || 0);
                  }

                  onDurationChange(
                    (selectedHour || 0) - 1,
                    selectedMinute || 0
                  );
                }}
              />
            </div>

            <div className="TimeSelector__content--colon">:</div>

            <div className="TimeSelector__content--body--group">
              <Icon
                className="rotate-top"
                icon="chevron-down"
                onClick={() => {
                  if (is24Hour) {
                    return toast.warn(INFO_STRINGS.disableAllDay);
                  }

                  const minute = snapToNearestFive(selectedMinute || 0);

                  const newMinute = minute === 45 ? 0 : minute + 15;
                  onDurationChange(selectedHour || 0, newMinute);
                }}
              />

              <input
                type="number"
                min="0"
                max="55"
                step="15"
                value={formatTwoDigits(selectedMinute || 0)}
                onChange={handleMinuteChange}
                tabIndex={-1}
                readOnly
              />

              <Icon
                icon="chevron-down"
                onClick={() => {
                  if (is24Hour) {
                    return toast.warn(INFO_STRINGS.disableAllDay);
                  }

                  const minute = snapToNearestFive(selectedMinute || 0);

                  const newMinute = minute === 0 ? 45 : minute - 15;
                  onDurationChange(selectedHour || 0, newMinute);
                }}
              />
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};
