const helpCenter = `<a href="https://support.gogetcorp.com/hc/en-us" target="_blank" rel="noopener">Help center</a>`;

export const tooltips = {
  dashboard: {
    heading: `<p>Overview of your GOGET Smart Workspaces. Check our ${helpCenter} for assistance.</p>`,
    workplaces: `
      <p>See:</p>
      <p>
      -Total number of rooms/desks/wayfinders<br />
      
      -Total number of rooms/desks/wayfinders with an allocated license<br />
      </p>
      <p>Rooms/desks/wayfinders need to have an allocated license to function properly.</p>
    `,
    calendars: `
      <p>See:</p>
      <p>
      -Total number of calendar integrations<br />

      -Total number of rooms which have a resource calendar assigned.<br />
      </p>
      <p>Rooms needs to have an assigned resource calendar.</p>
    `,
    devices: `
      <p>See:</p>
      <p>
      -Total number of devices paired<br />

      -Total number of devices assigned to a room.<br />
      </p>
      <p>Devices need to be assigned to a room.</p>
    `,
    insights:
      "<p>Total number of meetings in rooms for the last seven days.</p>",
    subscriptions:
      "<p>Subscriptions for your account. Cancelled subscriptions need to be re-activated by Goget support.</p>",
    billing: "<p>Invoices for your subscriptions.</p>",
  },
  workplaces: {
    tree: `
    <p>Add items to the tree structure in order to model the layout of your workspace resources.</p>
  
    <p>Check our ${helpCenter} for assistance.</p>

    <p>
    The structure is hierarchical with the following item types:<br />
    -Company<br />
    -Site<br />
    -Building<br />
    -Floor<br />
    -Zone<br />
    -Room<br />
    -Desk
    </p>
    <p>Room and desk are bookable resources which needs to be assigned to a calendar connection.</p>
    <p>Please note that the structure is non-strict so you can add e.g. rooms directly. </p>
    `,
    company: `
      <p>Company contains the following:</p>
      <p>
      -View: all items<br />
      -Settings: configuration of the company
      </p>
    `,
    site: `
      <p>Site contains the following:</p>
      <p>
      -View: all items<br />
      -Settings: configuration of the site
      </p>
    `,
    building: `
      <p>Building contains the following:</p>
      <p>
      -View: all items<br />
      -Settings: configuration of the building
      </p>
    `,
    floor: `
      <p>Floor contains the following:</p>
      <p>
      -View: all items<br />
      -Settings: configuration of the floor
      </p>
    `,
    zone: `
      <p>Zone contains the following:</p>
      <p>
      -View: all items<br />
      -Settings: configuration of the zone
      </p>
    `,
    room: `
      <p>Room contains the following:</p>
      <p>
      -Device: paired devices<br />
      -Room Settings: configuration of the Room<br />
      -Display Settings: configuration of the Room Display app.
      </p>
    `,
    wayfinder: `
      <p>Wayfinder contains the following:</p>
      <p>
      -Device: paired devices<br />
      -Wayfinder Settings: configuration of the Wayfinder<br />
      -Display Settings: configuration of the Wayfinder Display app<br />
      -Directions: configuration of the added rooms.
      </p>
    `,
    desk: `
      <p>Desk contains the following:</p>
      <p>
      -Settings: configuration of the Desk. <br />
      </p>
    `,
    name: `<p>Name of the item as shown in the workspace navigation tree.</p>`,
    description: `<p>Description of the item. Optional information to easily identify items.</p>`,
    tags: `<p>Tags for the item. Used to label items according to your preference.</p>`,
  },
  desks: {
    assignedUser: `<p>Assign a user to the desk so it wont be available to be booked by others.</p>`,
    workingHrsActivated: `<p>Restrict so that reservations can only be done during working hours.</p>`,
    checkIn: `<p>Check-in Required is a feature that requires users to check in to their desk reservations. If they do not check-in the reservation will be automatically deleted/released 15 min after the reservation is due, whereafter it will be available for others to reserve. During this 15 min buffer time, check-ins are not allowed. The check-in time window for check-in is set before the reservation is due, excl 15 min buffer time. The default is 1 hour, however you can change this between 1-4 hours.</p>`,
    checkInTime: `<p>Means “check-ins” open and will become available 1-4 hours before the reservation is due. Eg) A desk reservations starts 9 am. With a 1 hour window, the check-in opens at 8 am. If the reservation has not been confirmed/checked in by 9 am, the desk will be auto-released at 9:15.</p>`,
    blockDesk: `<p>Block the desk to prevent reservations.</p>`,
    qrCode: `<p>Requires users to check-in by scanning a QR code with the mobile app. Download the QR code and apply it to the desk.</p>`,
    deskSelect: `<p>Assign a desk to this user.</p>`,
    bulkDeskName: `<p>Name all the newly created desks.</p>`,
    bulkDeskNumber: `<p>Type the number of desks that you need to create. Please write a value between 2-25.</p>`,
    bulkDeskNumberIndex: `<p>Type a number from where you wish to start naming the desks.</p>`,
    license: `<p>Assign a license to the desk. Needed in order for users to find & reserve it.</p>`,
  },
  rooms: {
    settings: {
      seats: `<p>Number of available seats for the room.</p>`,
      amenities: `<p>Select which amenities the room is equipped with. Multiple items can be added.</p>`,
      reportEmail: `<p>Add which email address user generated error reports are sent to. The error report contains information on what type of amenity that has issues and its location.</p>`,
      changeEmail: `<p>Change the default email address</p>`,
      calendarSource: `<p>Select the calendar integration which contains the room resource calendar for the room.</p>`,
      calendarEmail: `
      <p>Add the room resource calendar for the room from the drop down list.</p>
      <p>
      Note!<br />
      - For EWS connections you need to type the calendar email.<br />
      - If you can’t see any calendars, check the FAQ section for your calendar integration.
      </p>
      `,
      assignLicense: `<p>Assign a license to the room. Needed in order for the room display device to show room information.</p>`,
      blockRoom: `<p>Block the room to prevent reservations.</p>`,
    },
    main: {
      adminPin: `
        <p>Activates an administrator PIN which prevents users from accessing admin settings.  If the field is left empty all users will be able to access settings.</p>
        <p>PIN needs to be 4 digits ranging from 0-9.</p>
      `,
      background: `
      <p>The look and feel of Room Displays can be customized by adding a different background. Enable this by entering a full URL to a JPEG or PNG file where the background image is located (e.g. https://www.example.com/background.jpg). Remember that large image files can take some time to load. Also note that your unit needs to have access to the entered URL.</p>
      <p>The background does not need to have a specific color. The app converts it to grey scale automatically, unless option “Override B/W filter” is checked, and caches it locally on the device.</p>
      `,
      logo: `<p>A customized company logo which is always shown can be added to the display. Enable this by entering a full URL to a JPEG or PNG file where the logo image is located (e.g. https://www.example.com/logo.jpg). Remember that large image files can take some time to load, so please be patient. Also note that your unit needs to have access to the entered URL.</p>`,
    },
    display: {
      showOrganizer: `
      <p>Select if the organizer for the meeting should be displayed.</p>
      <p>Note! If you're running Exchange and you don't see an organizer even if you have enabled this option it's because of a permission setting in Exchange. Go to the FAQ on gogetcorp.com for information on how to solve it.</p>
      `,
      showTitle: `<p>Select if the title of the meeting should be displayed.</p>`,
      showAttendees: `
      <p>Show meeting attendees.</p>
      <p>Note! Requires that the calendar source is Google or Office 365</p>
      `,
      showCapacity: `<p>Add an icon to the main screen which shows the number of seats.</p>`,
      showAmenities: `<p>Enables room amenities which includes error reporting of equipment and request for services directly from the device.</p>`,
      checkIn: `<p>Check-in Required (Pending Meeting) is a feature that require users to physically check-in to meetings. If they do not check-in the meeting will be automatically deleted and hence the resource is available for new bookings. Grace-period for Pending meetings are set before/after the meeting time. Default is 15 minutes, however you can change this between 5-15 minutes.</p>
      <p>
      Note!<br />
      Meetings created a couple of minutes before their start time are automatically checked in.
      </p>
      `,
      soonFree: `<p>Enables the display to show the text “Soon Free” 10 minutes or less before the end of an ongoing meeting.</p>`,
      energySave: `<p>Software/device will go into sleep mode outside of bookable work hours. If bookable work hours is off, it will use the default times, typically 17:00-08:00 the following day</p>`,
      contactlessQR: `<p>Enable mobile app users to reserve room by scanning a QR code.</p>`,
      contentBoardActivated: `
      <p>Show Digital Signage content when the room is available, there is more than 30 minutes until next meeting and there has been no touch input for the past 2 minutes. The content can either be HTML or an image file.</p>
      
      <p>This feature is generally only supported for the latest generation of Android OS devices.</p>
      `,
      contentBoardURL: `<p>Enter a full URL for the HTML content (eg https://www.example.com/content/) or image file (eg https://www.example.com/image.jpg). </p>
      <p>
      Note!<br />
      Your unit needs to have unrestricted access to the URL.</p>`,
      enableScreenLock: `<p>Activates a screen lock which require users to enter a PIN code in order to unlock the display.</p>
       <p>Note that the “Admin PIN” under “Main settings” will also unlock the display.</p>`,
      screenLock: `<p>PIN needs to be 4 digits ranging from 0-9.</p>`,
      language: `<p>Change language for the meeting room display. Press “edit” to create a fully customized translation to your local language.</p>`,
      maximizedMapView: `<p>Maxmizing the map will make the map view bigger.</p>
      
      <p>Note that the list views will be disabled.</p>
      `,
      notificationReminder: `<p>Send an email reminder to the meeting's organizer.</p>`,
    },
    reservation: {
      onScreen: `<p>Allow users to book a meeting directly on the device.</p>`,
      showTitleInput: `<p>Allow the user to add a meeting title.</p>`,
      requireTitleInput: `<p>Require the user to add a meeting title.</p>`,
      showNameInput: `<p>Allow the user to add their name.</p>`,
      requireNameInput: `<p>Require the user to add their name.</p>`,
      enforceWorkingHours: `<p>Restrict so that reservations can only be done during working hours for the room.</p>`,
      editMeetingSettings: `<p>Select which changes to events users are allowed to make on the display.</p>`,
      deleteOption: `<p>Allow users to delete meetings. </p>`,
      endEarlyOption: `<p>Allow users to end meetings early. </p>`,
      extendOption: `<p>Allow users to extend meetings. </p>`,
      nearbyRooms: `<p>Select which other meeting rooms users can view and reserve on the display. For other rooms to be displayed, they must:</br>
      </br>
      -Be assigned a license. </br>
  
      -Have a working calendar assigned.
        </p>
      `,
      oneTimePinProtection: `<p>Allow users to protect their on-screen reservations with a one-time PIN code. When applied reservations done on-screen cannot be modified/deleted by anyone but the user, or the administrator using the “Admin PIN”.</p>`,
      mapView: `<p>Show a map view for a selected floor.</p>
     
     <p>Note that it requires that a floor plan is added in section “Manage->Maps”.</p>`,
      requireHeadcount: `<p>Allow users to add number of participants for meetings. Used in Insights section to measure the attendance rate for rooms.</p>`,
    },
  },
  wayfinders: {
    settings: {
      items: `<p>Add which rooms & desks that are displayed.</p>`,
      picker: `<p>Add up to 24 rooms and 42 desks. Note that if map view is activated rooms/desks needs to be located on the same floor.</p>`,
      license: `<p>Assign a license to the wayfinder. Needed in order for the wayfinder display to show room/desk information.</p>`,
    },
    main: {
      background: `
      <p>The look and feel of Wayfinder Displays can be customized by adding a different background. Enable this by entering a full URL where the background image is located (e.g. https://www.example.com/background.jpg). Remember that large image files can take some time to load. Also note that your unit needs to have access to the entered URL.</p>
      <p>The background does not need to have a specific color. The app converts it to grey scale automatically and caches it locally on the device.</p>
      `,
    },
    displaySettings: {
      language: `<p>Change language for the wayfinder display. Press “edit” to create a fully customized translation to your local language.</p>`,
      adHoc: `<p>Allow users to make ad-hoc reservations on the screen.</p>`,
      occupancy: `<p>Show occupancy cards with general information on number of available rooms/desks and office occupany.</p>
      
      <p>Note that only rooms/desks in the same location as the Wayfinder will be included in the occupancy status.</p>
      `,
      switchTemp: `<p>Adjust the time interval for switching between lists for rooms and desks.</p>`,
    },
  },
  calendars: {
    connections: `
    <p>List of your calendar integrations. The system supports Exchange 2007/2010/2013/2016/Office 365 and Google Workspace/Calendar. Press “Add Integration” and follow the guide to add your first integration.</p>
    
    <p>Check our ${helpCenter} for assistance.</p>
    
    <p>
    Note that:<br />
    -The Goget calendar integration will only relay calendar events and do not permanently store them.<br />
    -You can add several calendar integrations of the same type (only pro plan).
    </p>
    `,
    o365: `<p>Press to re-sync the room resource calendars that your calendar integration has access to. In order for the calendars to be listed they need to be properly configured in Microsoft 365.</p>
     <p>Can’t list all calendars? Do these steps: </br>
    </br>

      1. Login to Exchange admin in O365 with an admin account: <a href="https://admin.exchange.microsoft.com/" target="_blank" rel="noopener noreferrer">https://admin.exchange.microsoft.com/</a> </br>

      2. Go to “Recipients -> Resources” in the left main menu. </br>

      3. Make sure that the Service Account (the account you integrated GOGET to Microsoft 365 with) has “Full access” permission under “Manage delegates” for each of the room resources. </br>

      4. Login to O365 as the Service Account user and then open the Calendar App: <a href="https://outlook.office.com/calendar" target="_blank" rel="noopener noreferrer">https://outlook.office.com/calendar </a> </br>

      5. Press “Add Calendar -> Add from directory” then enter the name or email address of the Room Resources in the field. Under “Add” select “Other Calendars”. When finished the Room Resources should be listed in the left column of the main view. </br>

      6. Allow for up to 48 hours until changes take effect.</p>
      
      <p>Check our ${helpCenter} for assistance.</p>
      `,
    gSuite: `<p>Press to re-sync the room resource calendars that your calendar integration has access to. In order for the calendars to be listed they need to be properly configured in Google Workspaces.</p>
    <p>Can’t list all calendars? Do these steps: </br>
    </br>
  1. Login to: <a href="https://calendar.google.com/" target="_blank" rel="noopener noreferrer">https://calendar.google.com/</a> with an admin account </br>

  2. If the Room Resources are not visible under My calendars list then add them  under “Other Calendars”, click Add -> Browse resources”. Then click the building or location for the resource. Finally next to the resource, check the box to add it to your My calendars list. </br>

  3. From the “My calendars” list, point to the resource and click More ->  Settings and sharing. Under “Share with specific people” add the Service Account and select “Make changes to events”. </br>

  4. Login to Google Calendar as the Service Account user:  <a href="https://calendar.google.com" target="_blank" rel="noopener noreferrer">https://calendar.google.com</a> </br>

  5. Under “Other Calendars”, click Add -> Browse resources”. Then click the building or location for the resource. Finally next to the resource, check the box to add it to your My calendars list. When finished the Room Resources should be listed in the left column of the main view. </br>

  6. Allow for up to 48 hours until changes take effect.</p>
  
     <p>Check our ${helpCenter} for assistance.</p>
  `,
    calendar: {
      integrations: `<p>List of your calendar integrations. Click to configure each calendar integration.</p>`,
      heading: `
      <p>Information for your calendar connection. Press the “Sync” icon to re-load room resource calendars.</p>
      <p>Can’t see any calendars? Go to the FAQ on gogetcorp.com for information on how to resolve it.</p>
      `,
      sync: `
      <p>Press to re-sync the room resource calendars that your calendar integration has access to. In order for the calendars to be listed they need to be properly configure in Exchange/Microsoft 365/Google.</p>
      <p>Can’t see any calendars? Go to the FAQ on gogetcorp.com for information on how to resolve it.</p>
      `,
    },
    ews: {
      heading: `
      <p>Information for your calendar connection. Press “Settings” to adjust the Service Account credentials.</p>
      <p>Check our ${helpCenter} for assistance.</p>
      `,
      view: `<p>Add room resource calendar email to the room/desk item in the Workspaces section. This is done in the “Room Settings” tab for rooms.</p>`,
      name: `<p>Display name for the calendar connection. Note! Only used in Goget system, so does not have to correspond to the name in Exchange.</p>`,
      url: `
        <p>For on-premise Exchange servers the communication is by EWS (Exchange Web Services) protocol which is supported by all Exchange servers from version 2007 and newer. Your EWS server must allow incoming requests from our server. Please refer to <a href="https://support.gogetcorp.com/hc/en-us/articles/6820603645852-Set-firewall-rules" target="_blank" rel="noopener">this</a> help center article for firewall requirements.</p>
        <p>Enter the EWS URL for your Exchange server in order for Room Display X to connect. It must be the full URL, for example: https://mail.company.com/EWS/Exchange.asmx. Common error is using the Outlook Web Access URL:s that contains: /OWA/ (instead of /EWS/). This will not work.</p>
        <p>
        Don't know your EWS URL?<br />
        -Go to help center article: <a href="https://support.gogetcorp.com/hc/en-us/articles/6820480306844-Determining-the-Exchange-Web-Services-EWS-URL" target="_blank" rel="noopener">Determining the Exchange Web Services (EWS) URL</a>.
        </p>
        `,
      username: `
        <p>Service Account is an account in Exchange with its own mailbox and <b>FullAccess</b> permission to the Room Resources. It is used by Goget to access booking information in your Room Resources.</p>
        <p>
          Exchange uses two types of username syntaxes. The old style NETBIOS name and the newer style UPN (User Principal Name). Depending on which your organization use the setup is different:<br />

          -UPN (e.g. username@domain.com)<br />
          UPN styled usernames are in this style: username@domain.com. If you use this style, then enter the full email address.<br />

          -NETBIOS Style (e.g. DOMAIN\\Username)<br />
          Old-styled usernames start with the domain name and then the username after a slash (\\). If you use this style, then enter in format "domain\\username".<br />
        </p>
        <p>Unsure on how to add/configure a Service Account in Exchange? Go to: <a href="https://support.gogetcorp.com/hc/en-us/sections/6795759512476-Office-365-Exchange-Integration" target="_blank" rel="noopener">Help center</a> or contact your IT administrator for assistance on how to configure.</p>
        `,
      password: `
          <p>Enter the Service account password.</p>
        `,
      impersonation: `<p>Note: This option is intended for advanced users and should generally remain disabled. When enabled, it changes the access method to Impersonation instead of the default Delegation for connecting to the Exchange server. For environments with more than 15 rooms, we recommend using Impersonation for better performance.</p>
      <p>Unsure on how to setup Impersonation for your Service Account in Exchange? Go to: <a href="https://support.gogetcorp.com/hc/en-us/sections/6795759512476-Office-365-Exchange-Integration" target="_blank" rel="noopener">Help center</a> or contact your IT administrator for assistance on how to configure.</p>
      `,
    },
    heading: `<p>Information for your calendar connection. Press the “Sync” icon to re-load room resource calendars.</p>
    
        <p>Check our Help center for troubleshooting <a href="https://support.gogetcorp.com/hc/en-us/sections/6795759512476-Office-365-Exchange-Integration" target="_blank" rel="noopener">Exchange/Microsoft 365</a> or <a href="https://support.gogetcorp.com/hc/en-us/sections/6813178078620-Google-Workspace-Integration" target="_blank" rel="noopener">Google Workspace</a>.</>

      <p>Can’t see any calendars? Press the tool tip next to “Looks like you haven't added any calendar emails yet!” text.</p>
      `,
    microsoftSignIn: `
      <p>
      Authorize the integration with your Micorosft 365/Exchange Online account. The Service Account used needs to meet these criteria:<br />
      -Delegated access to room resources (Full Access permission)<br />
      -Room resources needs to be shared with the Service Account.
      </p>
    `,
    googleSignIn: `
    <p>
    Authorize the integration with your Microsoft 365/Exchange Online account. The Service Account used needs to meet these criteria:<br />
    -”Make changes AND manage sharing” access to calendars.<br />
    -All resources added to “My Calendars” list in Google Calendar.
    </p>
    `,
    wizard: {
      addExchange: `Applies to: Exchange 2007/2010/2013/2016/2019 On-Premise`,
      addOffice: `Applies to: Microsoft 365 (formerly Office 365/Exchange Online) + Teams`,
      addGoogle: `Applies to: Google Workspaces/Calendar`,
    },
    emptyGSuite: `<p>In order for calendars to be listed these steps are needed: </br>
    </br>
  1. Login to: <a href="https://calendar.google.com/" target="_blank" rel="noopener noreferrer">https://calendar.google.com/</a> with an admin account </br>

  2. If the Room Resources are not visible under My calendars list then add them  under “Other Calendars”, click Add -> Browse resources”. Then click the building or location for the resource. Finally next to the resource, check the box to add it to your My calendars list. </br>

  3. From the “My calendars” list, point to the resource and click More ->  Settings and sharing. Under “Share with specific people” add the Service Account and select “Make changes to events”. </br>

  4. Login to Google Calendar as the Service Account user:  <a href="https://calendar.google.com" target="_blank" rel="noopener noreferrer">https://calendar.google.com</a> </br>

  5. Under “Other Calendars”, click Add -> Browse resources”. Then click the building or location for the resource. Finally next to the resource, check the box to add it to your My calendars list. When finished the Room Resources should be listed in the left column of the main view. </br>

  6. Allow for up to 48 hours until changes take effect.</p>
  
    <p>Check our ${helpCenter} for assistance.</p>
  `,
    emptyO365: `<p>In order for calendars to be listed these steps are needed: </br>
    </br>

      1. Login to Exchange admin in O365 with an admin account: <a href="https://admin.exchange.microsoft.com/" target="_blank" rel="noopener noreferrer">https://admin.exchange.microsoft.com/</a> </br>

      2. Go to “Recipients -> Resources” in the left main menu. </br>

      3. Make sure that the Service Account (the account you integrated GOGET to Microsoft 365 with) has “Full access” permission under “Manage delegates” for each of the room resources. </br>

      4. Login to O365 as the Service Account user and then open the Calendar App: <a href="https://outlook.office.com/calendar" target="_blank" rel="noopener noreferrer">https://outlook.office.com/calendar </a> </br>

      5. Press “Add Calendar -> Add from directory” then enter the name or email address of the Room Resources in the field. Under “Add” select “Other Calendars”. When finished the Room Resources should be listed in the left column of the main view. </br>

      6. Allow for up to 48 hours until changes take effect.</p>
      
      <p>Check our ${helpCenter} for assistance.</p>
      `,
  },
  users: {
    email: "Sample text",
    role: "Sample text",
    team: "Sample text",
    editUser: `<p>Edit user.</p>`,
  },
  devices: {
    heading: `<p>List of paired devices. Check our ${helpCenter} for assistance.</p>`,
    name: `<p>Display name for the device. Note! Only used in Goget system.</p>`,
    description: `<p>Description of the device. Used to easily locate devices in user applications</p>`,
    room: `<p>Assigns a room for the device and loads the configured display settings.</p>`,
    wayfinder: `<p>Assigns a wayfinder for the device and loads the configured display settings.</p>`,
    pairingCode: `<p>Enter the pairing code shown on your meeting room display. For instructions on how to install the app, click the help icon below the example image.</p>`,
    downloadRdx: `<p>For Android tablets:</p>
    
    <p> - Go to the device’s web browser and enter the URL: <a href="https://admin.gogetcorp.com/download/rdx" target="_blank" rel="noopener">https://admin.gogetcorp.com/download/rdx</a> </p>
    
    <p> - The app will be downloaded and can be installed directly. </p>

    <p> For GOGET devices:</p>
    <p> - Contact our sales team.</p>`,
    downloadRfx: `<p>For Android devices:</p>
    
    <p> - Go to the device’s web browser and enter the URL: <a href="https://admin.gogetcorp.com/download/wfx" target="_blank" rel="noopener">https://admin.gogetcorp.com/download/wfx</a> </p>
    
    <p> - The app will be downloaded and can be installed directly. </p>

    <p> For GOGET supplied devices:</p>
    <p> - Contact our sales team.</p>`,
    autoUpdate: `<p>App will automatically check for updates each 24 hours. Recommended setting is “ON”.</p>`,
  },
  account: {
    heading: `<p>Adjust your personal and business profile</p>`,
    email: `<p>A valid e-mail address. All e-mails from the system will be sent to this address. The address is not made public and will only be used if you wish to receive a new password or wish to receive certain news or notifications.</p>`,
    changePassword: `<p>Change the login password for your account. This can also be done with the reset password option on the login page. </p>`,
    profilePicture: `<p>Add and crop your profile picture which is used in the dashboard and Goget apps.</p>`,
    manage: `<p>Manage your company billing information and subscriptions</p>`,
    businessLogo: `<p>Add and crop your business logo which is used in the dashboard and Goget apps.</p>`,
    singleSignOn: `<p>Allow users to sign in with their Microsoft 365 or Google Account. Requires Pro plan.</p>`,
    enableDataCollection: `<p>Allow GOGET to store basic information about events.</p>`,
    showOrganizerForEvents: `<p>	
Do not display the organizer's name in the history section of Insights for rooms/desks.</p>`,
  },
  floorPlanHeader: `<p>Add floor maps for your workspaces to enable easy and convenient overview and reservation of rooms and desks.</p>
  <p>Check our ${helpCenter} for assistance.</p>
  `,
  floorPicker: `<p>Select which floor to view.</p>`,
  floorPusher: `<p>Manually push map updates to Wayfinder display(s)</p>`,
  billings: `<p>Adjust your subscription and billing information. Check our ${helpCenter} for assistance.</p>`,
  workingsHours: `<p>Select to override the global value for work hours.</p>
  <p>Only this workspace will be affected.</p>`,
  editWorkHours: `<p>Adjust the global working hours for your organization.</p>`,
  personalCalendar: {
    linkPersonalCalendar: `<p>Link your personal calendar to allow full functionality when reserving rooms. With this mode reservations are done directly into your personal calendar.</br>
  </br>
  Note that the personal calendar email needs to be identical to your user account email.</p>`,
    personalEWSName: `<p>Choose a name to describe the personal calendar connection.</p>`,
    personalEWSUrl: `
        <p>Enter the EWS URL for Exchange in order to link your personal calendar. It must be the full URL, for example: https://mail.company.com/EWS/Exchange.asmx</p>
        <p>Common error is using the Outlook Web Access URL:s that contains: /OWA/ (instead of /EWS/). This will not work.</p>
        <p>
        Don't know your EWS URL?<br />
        -Contact your organizations IT admin.
        </p>
        `,
    personalEWSUsername: `
    <p>Add your username for Exchange.</p>
    <p>
    Exchange uses two types of username styles. Depending on which your organization use the setup is different:<br />
    -username@domain.com (UPN style). If you use this style, then enter the full email address.<br />
    -DOMAIN\\Username (NETBIOS Style) with the domain name and then the username after a slash (\\). If you use this style, then enter in format "domain\\username".<br />
   </p>
    <p>
   Unsure on how to add your username?<br /> 
  -Contact your organizations IT admin.
   </p>
    `,
    personalEWSPassword: `<p>Enter the password for your Exchange account.</p>`,
  },
  generalSettings: {
    allowNotifications: `<p>Allow notifications of upcoming reservation check-ins.</p>`,
    findMe: `<p>Allow colleagues to find your reservations.</p>`,
    assignedDesk: `<p>Your permanently assigned desk. Contact your admin to adjust.</p>`,
  },
  defaultLocation: {
    chooseLocation: `<p>Select default location which will be preset when searching for available rooms and desks.</p>`,
    locationPicker: `<p>Select default location.</p>`,
  },
  planPrice: {
    basic: `<p>Essential room booking features at a great price for up to 5x meeting room displays.</p>`,
    business: `<p>Full suite of products for the modern hybrid workplace. Available in 1- or 3-year periods.</p>`,
    pro: `<p>Additional enterprise level functionality and dedicated customer service. Available in 1- or 3-year periods.</p>`,
  },
  bookings: {
    bookingsMain: `<p>Manage your reservations settings.</p>`,
    reservations: `<p>List of your reservations per day</p>
    <p>The number of reservations displayed depends on whether you have linked your personal calendar via the “Settings -> Link Personal Calendar” menu. </br>
   
    </br>
   <b>No Linked Personal Calendar (Butler Mode):</b> If your personal calendar is not linked, GOGET will function in Butler Mode, making room reservations on your behalf and sending invites. In this mode, you will only see room reservations made through GOGET. </br>

    </br>
    <b>Linked Personal Calendar:</b> When your personal calendar is linked, all room reservations from your calendar will be displayed. If you are not the meeting organizer, an attendee icon will indicate your role in the meeting.
    </p>
    
    `,
    findAndReserve: `<p>Find and reserve rooms or desks in your organization. Check our ${helpCenter} for assistance.</p>`,
    manuallyScanDesk: `<p>This desk requires that you manually scan its QR code with the GOGET mobile app.</p>`,
    info: `<p>See “My reservations” page in the web dashboard or mobile app to check.</p>`,
    rooms: {
      subject: `<p>Enter the title of your meeting.</p>`,
      addPeople: `<p>Add attendees to your meeting. Type the attendee email address manually if you wish to invite external users.</p>`,
      onlineMeeting: `<p>Add link to a Teams (Microsoft 365) or Meet (Google Workspace) meeting.</p>`,
      allDay: `<p>Reserve for the whole day.</p>`,
    },
    allReservationPicker: `<p>Select the room and desks you want to view the daily schedule for.</p>`,
    desks: {
      findPeople: `<p>Find your colleagues desk reservations for a specific time and date.</p>`,
      assignedDesks: `<p>You can't reserve or check in to your assigned desk. It's blocked for others to reserve or use.</p>`,
    },
    map: {
      heading: `<p>Select which floor plan to show.</p>`,
      floorHeading: `<p>See booking status for rooms & desks.</br>
      </br>
Now (clock icon): select which time and date to see reservations for. </br>
</br>
Spotlight: find colleagues that have reservations for the selected time period.</p>`,
    },
    location: `<p>Select a location for your search</p>`,
  },
  people: {
    heading: `<p>Invite and manage users. Regular users can make reservations either in the Web Dashboard or Mobile app.</p>`,
    inviteWithEmail: `<p>Send email invitation for a new user. The user will complete the registration by adding their details and password.</p>`,
    signInMethod: `<p>Select primary sign in method. Sign in with Microsoft 365 or Google Account require Pro plan.</p>`,
    role: `<p>Member role have access to make reservations in the Web Dashboard and Mobile app.</p>`,
    assigneDesk: `<p>Assigned a fixed desk for the user.</p>`,
    edit: `<p>Active: account activated and license assigned. </br>
</br>
        Inactive: account activated, but no assigned license. </br>
</br>
        Pending: account invitation sent, but user has not completed the registration process.</p>`,
    email: `<p>Login email for the user. Note that external email domain users may have limited functionality.</p>`,
    firstName: `<p>First name.</p>`,
    lastName: `<p>Last name.</p>`,
    license: `<p>Assigns a license for the user. Required for them to use the Web Dashboard or Mobile App.</p>`,
  },
  organizationSettings: {
    billingInfo: `<p>Add company information for billing purposes. We recommend that EU companies add their EU VAT number.</p>`,
    subscription: {
      info: `<p>Press “Edit” to open a widget were your subscription plan and items can be adjusted. </br>
</br>
Press “Compare plans” to see a comparison of all plans and their features.</p>`,
      products: `<p>Buy meeting room hardware and customized floor plan maps for your office.</p>`,
    },
    channelPartner: `<p>Add the email of your Channel Partner in order for them to fully manage your subscriptions.</p>`,
    payment: {
      creditCard: `<p>Add credit card details for automatic payment of subscriptions & products. </br>
      Credit card details are stored by our payment gateway Stripe. They are one of the worlds leading global payment processors trusted by millions of customers (see: <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">https://stripe.com/</a> for more information).</p>`,
      invoice: `<p>Apply to pay with international bank wire transfer using SWIFT/IBAN. </br>
      The application will need to be approved by Goget.</p>`,
    },
    generalSettings: {
      header: `<p>General settings for your organization</p>`,
      bookingsNotifications: `<p>Set global work days and hours and email address for service emails such as error reports from meeting room displays.</p>`,
      insights: `<p>Turn insights data collection on/off and calculate workspace cost per hour.</p>`,
      debug: `<p>Debug information for Goget support.</p>`,
      timeZone: `<p>Set the time zone for which events and insights are displayed in.</p>`,
      disableSimultaneous: `<p>Block users from being able to reserve multiple desks for the same time period.</p>`,
      timeZoneModal: `<p>Select time zone, adjustments for summer time are done automatically.</p>`,
    },
    customerSettings: {
      status: `<p>Current status for your customers subscriptions:</br>
      </br>
      -Active: Active and will automatically renew upon expiry. </br>

      -Non renewing: Active but will automatically cancel upon expiry.</br>

      -In trial: Currently in a trial period.</br>

      -Paused: Active but have been temporarily stopped.</br>

      -Cancelled: Inactive and will not renew. Goget needs to re-activate it.
        </p>
      `,
      customerInfo: `<p>Customer name and email.</p>`,
      subscriptionPlan: `<p>Subscription plan and duration (1- or 3-years).</p>`,
      subscriptionItems: `<p>Items for which licenses can be assigned. See partner help guide for detailed information.</p>`,
      expiryDate: `<p>The date by which the subscription, along with all included items, must be renewed. Note that subscription status will determine if renewal is done automatically.</p>`,
    },
  },
  directorySync: {
    sync: `<p>	
Sync users from your Microsoft 365/Azure AD or Google Workspaces directory. Users can either be manually updated when needed, or automatically synced. </br>

Note that this page is only for sync, the actual users are added under “Users → People”. 
</p>`,
    newUserSetting: `<p>Configure settings for new users.</p>`,
    integrations: `<p>Add integration towards your Microsoft 365/Azure AD or Google Workspaces directory. The integration will connect to Graph API (Microsoft 365) or Directory API (Google Workspaces) and sync users within your tenant.</p>`,
    integrationPage: `<p>	
List of users and their current status. Select which users to update and then press “Sync users” button. </p> 
<p>
    There are three possible statuses: </br>

-New: not an existing GOGET user </br>

-Updated: existing GOGET user, but have updated properties </br>

-Removed: existing GOGET user, but NOT found in list of users synced from directory 
</p>`,
    autoSync: `<p>Enable auto sync of users on regular intervals. New users will receive an invitation email to register their account.</p>`,
    enableAutoSync: `<p>Turn on automatic user sync every 24 hours.</p>`,
    syncSelectLoginMethod: `<p>Select which method that new users should use to login. Note that SSO is only available for Pro plan.</p>`,
    syncLoginSelectLoginMethod: `<p>Select which method that new users should use to login. Note that SSO is only available for Pro plan.</p>`,
  },
  insights: {
    rooms: {
      heading: `
      <p>Insights for your organization based on data from meeting events.</p>
      <p>
      Data can be selected by: </br>
      Date range - select time period. </br>
      Location - select location in the workspace hierarchy. </br>
      </p>
      <p>
        Values are adjusted according to global work days & hours set in menu: Organizational Settings → General → Booking & notifications. </br>

        For data to show collection must be turned on in menu: Organizational Settings → General → Insights.
      </p>
      `,
      noShows: `<p>	
Number of meetings that were not checked in at the meeting room display and were subsequently automatically deleted.</p>`,
      reclaimed: `<p>
      Number of hours that were “reclaimed” for events automatically deleted by the check-in feature.
</p>
<p>For instance, consider Room A, which was booked from 10:00 to 16:00 but wasn't checked in for the reservation and was thus automatically deleted. If a user then reserved a meeting from 14:00 to 16:00 for the same room, this new reservation would be counted as reclaiming 2 hours of meeting room time.
      </p>`,
      meetings: `<p>Number of meetings per day or month.</p>`,
      lengthDistribution: `<p>	
Distribution in percentage for the duration of meetings.</p>`,
      totalOccupancy: `<p>Average occupancy with adjusted for global working hours.</p>`,
      occupancyByDay: `<p>Occupancy per weekday adjusted for global working hours.</p>`,
      mostVsLeastOccupied: `<p>Rooms with highest and lowest % occupancy adjusted for global working hours.</p>`,
      occupancyByRoomSettings: `<p>Occupancy per number of seats for room adjusted for global working hours.</p>`,
      densityByTimeDay: `<p>Number of meetings with start time at a specific time/day slot adjusted for global working hours.</p>`,
      meetingBehavior: `<p>
      Ad-hoc is defined as: </br>
       Booking time - meeting start time is equal to or less than 15 minutes. </br>

Planned: all meetings reserved more than 15 minutes before meeting start time.
      </p>`,
      mostLeastNoShows: `<p>Rooms with highest/lowest no-show %.</p>`,
      attendanceByRoomCapacity: `<p>
      % attendance per number of seats for room. </p>
<p>
For meetings the attendance % in relation to total number of seats is first calculated (for example 4x attendees in a room with 10 seats equal 40%). Then the average attendance % for all meetings is calculated and displayed. 
      </p>`,
      realizedMeetingsFunnel: `<p>
      The values are defined by: </br>

Booked = all meetings + meetings that were automatically deleted due to no-show.</br>

Released = no-show meetings automatically deleted.</br>

Reclaimed = no meetings reserved in time slot previously occupied by no-show meeting.
      </p>`,
      pathExploration: `<p>Flow of total number of meetings per location in workspace hierarchy.</p>`,
    },
    desks: {
      heading: `
      <p>Insights for your organization based on data from desk reservations.</p>
      <p>
      Data can be selected by: </br>
      Date range - select time period. </br>
      Location - select location in the workspace hierarchy. </br>
      </p>
      <p>
        Values are adjusted according to global work days & hours set in menu: Organizational Settings → General → Booking & notifications. </br>

        For data to show collection must be turned on in menu: Organizational Settings → General → Insights.
      </p>
      `,
      active: `<p>
      Active = number of desks with assigned license. </br>

Assigned = number of desks assigned to a user. </br>

Blocked = number of desks blocked for reservations. </br>

Unused = number of desks with no reservation during the time period.
      </p>`,
      noShows: `
      <p>
      No-show is number of reservations that were not checked-in and therefore automatically deleted.

</p>
<p>
For reclaimed reservations, consider Desk A, which was booked from 10:00 to 16:00 but wasn't checked in for the reservation and was thus automatically deleted. If a user then reserved from 14:00 to 16:00 for the same desk, this new reservation would be counted as reclaiming 2 hours of time.
      </p>
      `,
      reservations: `<p>Number of reservations per day or month.</p>`,
      reservationLengthDistribution: `<p>Distribution in percentage for the duration of reservations.</p>`,
      totalOccupancy: `<p>Average occupancy adjusted for global working hours.</p>`,
      occupancyByDayOfWeek: `<p>Occupancy per weekday adjusted for global working hours.</p>`,
      mostPopularDesks: `<p>Desks with highest % occupancy adjusted for global working hours.</p>`,
      leastPopularDesks: `<p>Desks with lowest % occupancy adjusted for global working hours.</p>`,
      deskReservationDensity: `<p>Number of reservations with start time at a specific time/day slot adjusted for global working hours.</p>`,
      reservationBehavior: `<p>
    Ad-hoc is defined as: </br>
    Booking time - reservation start time is equal to or less than 15 minutes. </br>

Planned are all reservations added more than 15 minutes before reservation start time.
    </p>`,
      mostNoShows: `<p>Desks with highest no-show %</p>`,
      leastNoShows: `<p>Desks with lowest no-show %</p>`,
      realizedReservationsFunnel: `<p>
Values are defined as: </br>

Booked = all reservations + reservations that were automatically deleted due to no-show </br>

Released = no-show reservations automatically deleted </br>

Reclaimed = no reservations reserved in time slot previously occupied by no-show meeting
    </p>`,
      pathExploration: `<p>Flow of total number of reservations per location in workspace hierarchy.</p>`,
    },
  },
  templates: {
    settingsTemplates: `<p>Create settings templates to quickly apply customized settings to multiple workspace items (rooms/desks) in bulk.</p>
    <p>Templates will replicate all settings for items. When applied to a room or desk, the settings configuration will be copied to that resource. Note that settings are copied from the template to the workspace item; therefore, any subsequent changes made to the template will need to be re-applied to the workspace items.</p>
    `,
    selectWorkspace: `<p>Choose the workspaces to which the template should be transferred.</p>`,
    transferrTemplate: `<p>Choose a template to transfer settings from.</p>`,
    transferSettings: `<p>Quickly transfer all or partial settings from a template to a workspace item. To provide maximum flexibility, you can freely choose which workspaces should receive new settings and which settings to transfer.</p>`,
    addRoomTemplate: `<p>Create a room template that contains all settings for room items</p>
    <p>Once the template is created, you can use the “Transfer Settings Templates” guide to apply the template to any room(s) within your organization.</p>
    `,
    editTemplate: `<p>Edit settings template configuration.<p/>`,
    profileName: `<p>Name of the profile.<p/>`,
    profileDescription: `<p>Description of the profile. Optional information to easily identify it.<p/>`,
    addDeskTemplate: `<p>Create a desk template that contains all settings for desk items</p>
    <p>Once the template is created, you can use the “Transfer Settings Templates” guide to apply the template to any desk(s) within your organization.</p>
    `,
  },
};
